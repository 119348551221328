import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import matt from '../images/matt.jpeg'
import sara from '../images/sara.jpeg'
import shan from '../images/shan.jpeg'
import tom from '../images/tom.jpg'

import 'normalize.css'
import './index.css'
import 'tachyons'

const IndexPage = () => (
  <Layout>
    <SEO title="Shuttleport" />
    <div>
      <Hero />
      <Fit />
      <Services />
      <Work />
      <Testimonials />
      <About />
      <Contact />
    </div>
  </Layout>
)

const SectionHeader = ({ children, className }) => (
  <div className={`f4 mb3 fw6 ${className}`}>{children}</div>
)

const ButtonLink = ({ children, to }) => (
  <div>
    <div to={to} className="ba b--black bw2 pa2 dim ">
      {children}
    </div>
  </div>
)

const Hero = () => (
  <div className="mb5 ph3 pa5-ns">
    <div className="white f2 mw7 b mb4 lh-title">
      Let's solve tough business problems and look good doing it.
    </div>
    <div className="white f3 mw6 lh-title black-70">
      Hi, I'm Javon. Businesses hire me to build custom web and mobile
      applications that cut costs and increase productivity.
    </div>
  </div>
)

const Fit = () => (
  <div className=" ph3 pb3 pa5-ns bg-white">
    <div className="f3 b mb4 pa2">I'm a good fit if you:</div>
    <div className="flex-ns">
      <div className="mb4 pa2 w-third-ns">
        <div className="f4 mb2 h3-ns b">Need more than just code</div>
        <p className="lh-copy">
          While fullstack development is my specialty, I'm passionate about
          creating products from idea to execution. I use my design,
          development, and product strategy skills create functional and usable
          products.
        </p>
      </div>
      <div className="mb4 pa2 w-third-ns">
        <div className="f4 mb2 b h3-ns">Want to stay lean</div>
        <p className="lh-copy">
          If you're not ready for your first hire or need to supplement your
          small team with extra manpower, check me out. I can help set up best
          practices with your product and team so when it's time to scale, you
          know exactly what to do.
        </p>
      </div>
      <div className="mb4 pa2 w-third-ns">
        <div className="f4 mb2 b h3-ns">Don't have enough hours in the day</div>
        <p className="lh-copy">
          Some of my favorite projects have been those that took real world
          processes and digitized them. I can help you automate tedious parts of
          your business and give back meaningful data along the way.
        </p>
      </div>
    </div>
  </div>
)

const Services = () => (
  <div className="pa3 pa5-ns" style={{ backgroundColor: '#F3F3F3' }}>
    <SectionHeader>
      <span className="bb bw2" style={{ borderColor: '#0065ff' }}>
        Services
      </span>
    </SectionHeader>
    <div className="flex-ns">
      <Expertise
        title="Product"
        skills={[
          'MVP Development',
          'Product Roadmapping',
          'User & Competition Research',
        ]}
      />
      <Expertise
        title="Web Development"
        skills={[
          'Frontend Development',
          'React / React Native',
          'Ruby on Rails',
        ]}
      />
      <Expertise
        title="UX & UI Design"
        skills={['User Experience Design', 'Design Sprints', 'Mobile Design']}
      />
    </div>
  </div>
)

const Expertise = ({ title, skills }) => (
  <div className="bg-white pa2 ma2-ns mv3 w-third-ns">
    <div className="lh-title b">{title}</div>
    <ul>
      {skills.map((skill, i) => (
        <li key={i} className="lh-copy">
          {skill}
        </li>
      ))}
    </ul>
  </div>
)

const Work = () => (
  <div className="pa3 pa5-ns" style={{ backgroundColor: '#0065ff' }}>
    <div className="flex-ns flex-row-ns">
      <SectionHeader className="white mr5-ns">Past Work</SectionHeader>
      <div>
        {work.map((project, i) => (
          <Project key={i} project={project} index={i} />
        ))}
      </div>
    </div>
  </div>
)

const Project = ({ index, project }) => (
  <div className={`mb4 black mw6 bg-white pa3 bw2`}>
    <div className="lh-copy black-50 b mb3">{project.years}</div>
    <div className="b f4">
      <span className="lh-title">{project.title} </span>
    </div>
    <div className="mb3">
      <div className="mb1 lh-copy ttu fw7 black-50">
        {project.industry} Industry
      </div>
      <div className="mb1 lh-copy">{project.skills}</div>
    </div>
    <div>
      <p className="lh-copy">{project.description}</p>
    </div>
  </div>
)

const Testimonial = ({ testimonial }) => (
  <div className="mb5 mw6-ns">
    <p className="mb3 lh-copy f6">{testimonial.quote} </p>
    <div className="flex">
      <img className="br-100 h3 w3 mr3" src={testimonial.image} />
      <div>
        <div className="mb1 f4">{testimonial.person} </div>
        <div className="black-60">{testimonial.title}</div>
      </div>
    </div>
  </div>
)

const Testimonials = () => (
  <div className="pa3 pa5-ns bg-white">
    <div
      className="testimonials"
      style={{
        gridGap: '0.5rem',
        gridTemplateRows: 'repeat(1fr, 1fr)',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      {testimonials.map((testimonial, i) => (
        <Testimonial key={i} testimonial={testimonial} />
      ))}
    </div>
  </div>
)

const About = () => (
  <div className="pa3 pa5-ns white-60 bg-black-90">
    <div className="mw6">
      <SectionHeader className="white">About</SectionHeader>
      <p className="lh-copy">
        {' '}
        Hi, I'm{' '}
        <a className="dim white-80" href="http://javonharper.com">
          {' '}
          Javon Harper
        </a>
        . I've been building digital products since 2011 and shuttleport is a
        collection of work that I've done directly with businesses since 2014.
        Aside from my client work, my side projects include the music discovery
        website{' '}
        <a className="dim white-80" href="http://goodnotes.io">
          {' '}
          Goodnotes.io
        </a>{' '}
        and personal productivity app{' '}
        <a
          className="dim white-80"
          href="https://play.google.com/store/apps/details?id=com.javonharper.familiar&hl=en"
        >
          Familiar
        </a>
        .
      </p>
    </div>
  </div>
)

const Contact = () => (
  <div className="pa3 pa5-ns white-60 bg-black-90">
    <div className="mw7">
      <SectionHeader className="white">Contact</SectionHeader>
      <div className="lh-copy pv2">
        I'm always interested in working on new projects.
      </div>

      <div className="lh-copy pv2">
        <a className="dim white" href="mailto:javon@shuttleport.io">
          Hire me
        </a>{' '}
        if you're interested in building something together.
      </div>
    </div>
  </div>
)

const testimonials = [
  {
    quote: [
      '"The work that Javon did for us over the past few months was instrumental – not only towards advancing key initiatives within our firm but also ',
      <span className="fw6">introducing new ideas and methodologies </span>,
      'that we will carry with us on future projects."',
    ],
    person: 'Sara Murphy',
    title: 'Director, Digital Solutions & Strategy at FS Investments',
    image: sara,
  },
  {
    quote: [
      '"Javon was able to help me ',
      <span className="fw6">bootstrap multiple SaaS products</span>,
      ' that saved my clients ',
      <span className="fw6">thousands per month </span>,
      'and had their staff working smarter and more efficiently than ever."',
    ],
    person: 'Matt Monihan',
    title: 'CEO at Voyager Scientific LLC',
    image: matt,
  },
  {
    quote: [
      '"As an early contributor to our product, Javon engaged with our customer stakeholders day 0 and helped ',
      <span className="fw6">
        translate the complexity of our business into a foundational
        infrastructure
      </span>,
      ' (both service level and client-side). As we moved to scale the product and long term team, he ',
      <span className="fw6">seamlessly transitioned his MVP work </span>,
      'to my engineering team."',
    ],
    person: 'Prashanth Donepudi',
    title: ' President & CTO at sinq Technologies ',
    image: shan,
  },
  {
    quote: [
      '"I’ve worked with Javon on a number of web development projects and I can say he’ll make a ',
      <span className="fw6">valuable addition to any project</span>,
      '. He’s a team player and is always reliable in his duties as a developer. Above all Javon is easy to work with and will have no problem cultivating strong relationships with you and your team."',
    ],
    person: 'Tom Piarulli',
    title: 'Independent Consultant',
    image: tom,
  },
]

const work = [
  {
    color: '#f26924',
    title: 'Serverless Startup',
    years: 'Q3 2018 - Q4 2019',
    industry: 'Serverless Monitoring',
    skills: 'React, Sketch, GraphQL, Express, Nodejs',
    description:
      'Hired as a Product Engineer, I contributed to the product on all fronts-- frontend, backend, and design. I worked closely with our founder, designer, and QA to figure out the right thing to build, and worked with service level engineers to connect the flow of data through our product. Typically working on the React frontend, I also worked in Sketch to wireframe UIs and maintained our GraphQL API to address data needs on the frontend. Maintaining the product and working on features in a team of 10, I helped them cross the finish line and get acquired by a NYSE-traded cloud-based software company.',
  },
  {
    color: '#f26924',
    title: 'SaaS Platform UX/Marketing site UX Redesign',
    years: 'Q4 2017 - Q1 2018',
    industry: 'Finance',
    skills: 'React, Sketch, Prototyping, Design Sprints',
    description:
      'While working with this client I took part in a number of initiatives, including participating in and leading Design Sprints, a set of practices for collaborative design and prototyping. I worked parallel in two in teams of 5 and 6, respectively, and rapidly built prototypes for each project to help the firm test and validate our direction.',
  },
  {
    color: '#3365a6',
    title: 'Mobile Startup MVP (as a mobile developer)',
    years: 'Q3 2016 - Q3 2017',
    industry: 'Healthcare',
    skills: 'React Native, JavaScript',
    description:
      'The founder requested I return to help on this project and this time I used my React Native skills to help their team build out their cross-platform app. When the time came to scale the project, I gladly transitioned my mobile MVP work to his dev team so that they were equipped with an understanding of the codebase they needed to face the future of the product.',
  },
  {
    color: '#9A2F28',
    title: 'Business Management SaaS App',
    industry: 'Construction',
    years: 'Q3 2015 - Q3 2016',
    duration: '1 year',
    skills:
      'SaaS Product, Ruby on Rails, JavaScript, HTML, CSS, Responsive Development, Docusign Integration',
    description:
      'I was the full-stack developer building a suite of internal SaaS products for a local construction company. This project replaces hard-to-manage employee onboarding and safety paperwork and digitizes forms so back-office admins can approve items, automate payroll tasks, and do their job more effectively.',
  },
  {
    color: '#3365a6',
    title: 'Mobile Startup MVP (as a backend developer)',
    industry: 'Healthcare',
    years: 'Q1 2015 - Q3 2015',
    duration: '6 months',
    skills:
      'API Design, Database Design, Ruby on Rails, HIPAA Compliance, Geolocation',
    description:
      'I worked in a team of 3 to design and build an API for a mobile startup MVP in the healthcare industry. My responsibilities included building endpoints that the mobile app would communicate with, architecting the design of our PostgreSQL database, and performing code reviews with my teammates.',
  },
  {
    color: '#74B8E7',
    title: 'Autism Referral SaaS App',
    industry: 'Healthcare',
    years: '2014 - Q3 2016',
    duration: '1 1/2 Years',
    skills: 'SaaS Product, Ruby on Rails, PostgreSQL, HIPAA Compliance',
    description:
      'A custom-built SaaS product for a business that helps home-based and district-based therapists log their appointments with special needs children. The site replaces paper forms by digitizing them, producting meaningful reports for billing cycles in the process. I worked directly with the company to help them and now the site is used by over a 100 therapists with 1000s of appointments being logged every month.',
  },
  {
    color: '#81D1BA',
    title: 'Online Marketplace Back-end',
    industry: 'Real Estate',
    years: '2014',
    duration: '3 months',
    skills: 'API Design, REST, Python, Django',
    description:
      'I was hired as a subcontractor to finish development of an API for a mobile-first web app MVP in the real estate industry. Challenges here included taking ownership of a codebase written by a past developer and working closely with a front-end developer to deliver a final product.',
  },
]

export default IndexPage
